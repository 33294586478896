import Layout from "./Layout";
import { Row, Col, Image, Button } from 'antd'

const Home = () => {
  return (
    <div style={{ margin: '0, 20px', backgroundColor: '#B71F07', color: 'white', fontSize: '16px' }}>
      <Row style={{ minHeight: '450px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url("images/bg.png")` }}>
        <Col align='left' offset={3} span={21} style={{ padding: '50px 20px', marginBottom: '100px' }}>
          <img className="img-fluid" style={{ maxHeight: "60%" }} src="images/1024.png" />
        </Col>

        <Col align='middle' span={24} offset={0} className="home-listim">
          <Row align='middle'>
            <Col align='middle' span={6} offset={3} style={{}}>
              <Image className="img-fluid" style={{ maxWidth: "95%" }} src="images/1.jpg" />
            </Col>
            <Col align='middle' span={6} style={{}}>
              <Image className="img-fluid" style={{ maxWidth: "95%" }} src="images/2.jpg" />
            </Col>
            <Col align='middle' span={6} style={{}}>
              <Image className="img-fluid" style={{ maxWidth: "95%" }} src="images/3.jpg" />
            </Col>
          </Row>
        </Col>

        <Col align='left' span={22} offset={2} style={{color: "white", paddingTop: '30px'}}>
          <p>There are many fun and engaging games you can play with your friends online:</p>
          <p>- Teanglen </p>
          <p>- Kla Klouk </p>
          <p>- Sab Sam </p>
          <p>- Bork Kdeng</p>
          <br></br>
          <p>This game is completely fun to play.</p>
          <br></br>
          <p>Support 24/7!</p>
        </Col>
      </Row>
    </div>
  )
};

export default Home;
