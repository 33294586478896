import React, { Component, useState } from 'react'
import { Link } from "react-router-dom";
import { Breadcrumb, Layout, Menu, MenuProps, theme, Col, Row } from 'antd';

const { Header, Content, Footer } = Layout;
const items = [
    {
        label: 'Home',
        key: '',
    },
    {
        label: 'Policy',
        key: 'policy',
    },
    // {
    //     label: 'Event',
    //     key: 'event',
    // }
]

class MyHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }


    render() {
        const onClickMenu = (e) => {
            console.log('click ', e);
            window.location.href = '/#/' + e.key
        };

        return (
            <Header style={{ backgroundColor: '#B71F07', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', backgroundImage: `url("images/he.png")` }}>
                <Row align="middle">
                    <Col span={24} align="middle">
                        <div className="logo">
                            {/* <img className="img-fluid" style={{ maxWidth: "90%" }} src="images/logo.png" alt="" /> */}
                        </div>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center', color: '#fff', padding: '12px' }} align="middle">
                        <a style={{ backgroundColor: '#B71F07', color: '#fff' }} href='/#/'>Home</a>
                        <span style={{ backgroundColor: '#B71F07', color: '#fff', padding: '0 10px' }} >|</span>
                        <a style={{ backgroundColor: '#B71F07', color: '#fff' }} href='/#/policy'>Policy</a>

                    </Col>
                </Row>
            </Header >
        )
    }
}

export default MyHeader;
