import { Row, Col, Image, Carousel } from 'antd'

const Policy = () => {
  return (
    <div style={{ backgroundColor: '#fff', color: 'black', fontSize: '16px' }}>
      <Row align="middle" style={{ padding: '20px' }}>
        <Col align="left" span={24}>
          <h3>ITSOL SYSTEM CO., LTD PRIVACY POLICY</h3>
          <p>This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here or our ITSOL SYSTEM CO., LTD website. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
            Last Edited on March 20, 2023</p>

          <h4>1. ABOUT THIS PRIVACY POLICY:</h4>
          <p>Welcome you to the service(s), product(s) operated by ITSOL SYSTEM CO., LTD and its affiliates and related companies (individually and collectively, "ITSOL SYSTEM CO., LTD", "we", "us" or "our"). ITSOL SYSTEM CO., LTD takes its responsibilities under applicable privacy laws and regulations ("Privacy Laws") seriously and is committed to respecting the privacy rights and concerns of all users of our games (“Games”) and website (the "Site") (we refer to the Games, Site and the services we provide collectively as the "services"). We recognize the importance of the personal data you have entrusted to us and believe that it is our responsibility to properly manage, protect and process your personal data. This policy (“Privacy Policy”) is designed to assist you in understanding how we collect, use, disclose and/or process the personal data you have provided to us and/or possess about you, whether now or in the future, as well as to assist you in making an informed decision before providing us with any of your personal data.  This Privacy Policy is also designed to assist ITSOL SYSTEM CO., LTD users who live in the European Union (the “EU”) to understand their applicable data privacy rights that exist under the EU's General Data Protection Regulation when they use the Services.
            “Services” or “Service”: means the Games, and/or Websites, and/or any service, product that ITSOL SYSTEM CO., LTD offer.
            "Personal Data" or "personal data" means data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which an organization has or is likely to have access. Common examples of personal data could include name, identification number and contact information.
            By using the Services, registering for an account with ITSOL SYSTEM CO., LTD or visiting our Site, you acknowledge and agree that you accept the practices, requirements, and/or policies outlined in this Privacy Policy, and you hereby consent to us collecting, using, disclosing and/or processing your personal data as described herein.
            IF YOU DO NOT CONSENT TO THE PROCESSING OF YOUR PERSONAL DATA AS DESCRIBED IN THIS PRIVACY POLICY, PLEASE DO NOT USE ITSOL SYSTEM CO., LTD SERVICES OR ACCESS ITSOL SYSTEM CO., LTD GAMES OR SITE. If we change our Privacy Policy, we will post those changes or the amended Privacy Policy on the Site. We reserve the right to amend this Privacy Policy at any time without your approval, the fact that you continue to use the service(s) by ITSOL SYSTEM CO., LTD would be considered as your consent to the amended Privacy Policy.</p>

          <h4>2. WHEN WILL ITSOL SYSTEM CO., LTD COLLECT YOUR PERSONAL DATA</h4>

          <p>ITSOL SYSTEM CO., LTD will/may collect personal data about you:
            when you register and/or use our services, games or sites, or create an account with us.
            when you submit any form, including, but not limited to, application forms or other forms relating to any of our products and services, whether online or by way of a physical form,
            when you enter into any agreement or provide other documentation or information in respect of your interactions with us, or when you use our products and services
            when you interact with us, such as via telephone calls (which may be recorded), letters, fax, face-to-face meetings, social media platforms and emails
            when you use ITSOL SYSTEM CO., LTD’s electronic services, or interact with us via ITSOL SYSTEM CO., LTD Games or Site or use ITSOL SYSTEM CO., LTD Services. This includes, without limitation, through cookies which we may deploy when you interact with our Games or Site
            when you carry out transactions through our Games or Site
            when you provide ITSOL SYSTEM CO., LTD feedbacks or complaints
            when you register for a contest, for games or game specific events
            during your gameplay; and
            when you submit your personal data to ITSOL SYSTEM CO., LTD for any reason
            The above only sets out some common instances of when personal data about you may be collected, it do not fully demonstrate every circumstance that ITSOL SYSTEM CO., LTD collect your personal data.</p>

          <h4>3. WHAT PERSONAL DATA WILL ITSOL SYSTEM CO., LTD COLLECT</h4>

          <p>The personal data that ITSOL SYSTEM CO., LTD may collect includes but is not limited to:
            - Name
            - Email Address
            - Telephone Number
            - Information sent by or associated with the device(s) used to access our service(s)
            - Any other information about you when signing up to use our Services, and when using the Services, as well as information related to how you use our Services; and
            - aggregate data on content the user engages with
            You agree not to submit any information to us which is inaccurate or misleading, and you agree to inform us of any inaccuracies or changes to such information. ITSOL SYSTEM CO., LTD reserve the right at our sole discretion to require further documentation to verify the information provided by you.
            If you use your social media account (Facebook, Google), application account (as Apple account) or your phone number signing up to be a user of ITSOL SYSTEM CO., LTD services, you fully understood and agree that ITSOL SYSTEM CO., LTD may access information about you which you have voluntarily provided to your Social Media Account provider in accordance with such provider's policies, and ITSOL SYSTEM CO., LTD will manage and use such personal data in accordance with this Privacy Policy at all times.
            If you do not want us to collect the aforementioned information/personal data, you may opt out at any time by notifying ITSOL SYSTEM CO., LTD’s Data Protection Officer in writing. Further information on opting out can be found in the section below entitled "How can you withdraw consent, remove, request access to or modify information you have provided to us?". Note, however, that opting out or withdrawing your consent for us to collect, use or process your personal data may affect your use of the Services. For example, opting out of the collection of location information will cause its location-based features to be disabled.</p>

          <h4>4. COLLECTION OF OTHER DATA</h4>
          <p>As with most websites and mobile applications, your device sends information which may include data about you that gets logged by a web server when you use ITSOL SYSTEM CO., LTD Services. This typically includes without limitation your device’s Internet Protocol (IP) address, computer/mobile device operating system and browser type, type of mobile device, the characteristics of the mobile device, the unique device identifier (UDID) or mobile equipment identifier (MEID) for your mobile device, the address of a referring web site (if any), the pages you visit on ITSOL SYSTEM CO., LTD website and mobile applications and the times of visit, and sometimes a "cookie" (which can be disabled using your browser preferences) to help the site remember your last visit. If you are logged in, this information is associated with your personal account. The information is also included in anonymous statistics to allow us to understand how visitors use our Games and Site.
          Our mobile applications may collect precise information about the location of your mobile device using technologies such as GPS, Wi-Fi, etc. ITSOL SYSTEM CO., LTD collect, use, disclose and/or process this information for one or more purposes including, without limitation, location-based services that you request or to deliver relevant content to you based on your location or to allow you to share your location to other users as part of the services under ITSOL SYSTEM CO., LTD mobile applications. For most mobile devices, you are able to withdraw your permission for us to acquire this information on your location through your device settings. If you have questions about how to disable your mobile device's location services, please contact your mobile device service provider or the device manufacturer.
          And we do not store nor share this information with any third parties.</p>

          <h4>5. COOKIES</h4>
          <p>We may from time to time implement "cookies" or other features to allow us or third parties to collect or share information that will help us improve our Site, Games and the Services we offer, or help us offer new services and features. “Cookies” are identifiers we transfer to your computer or mobile device that allow us to recognize your computer or device and tell us how and when the Services or website are used or visited, by how many people and to track activity within our Site and Games. We may link cookie information to personal data. Cookies also link to information regarding what items you have selected for purchase pages you have viewed, or games you have played. This information is used to keep track of your shopping cart, for example. Cookies are also used to deliver content specific to your interest and to monitor usage of the Services.
            You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this you may not be able to use the full functionality of our Site, Games or the Services.</p>

          <h4>6. HOW DO ITSOL SYSTEM CO., LTD USE THE INFORMATION THAT YOU PROVIDE?</h4>
          <p>We may collect, use, disclose and/or process your personal data for one or more of the following purposes
            to consider and/or process your application/transaction with us or your transactions or communications with third parties via the Services
            to manage, operate, provide and/or administer your use of and/or access to our Services, Site and Games (including, without limitation), your preferences, as well as your relationship and user account with us;
            to respond to, process, deal with or complete a transaction and/or to fulfil your requests for certain products and services and notify you of service issues and unusual account actions;
            to enforce our Terms of Service or any applicable end user license agreements
            to protect personal safety and the rights, property or safety of others;
            for identification and/or verification
            to maintain and administer any software updates and/or other updates and support that may be required from time to time to ensure the smooth running of our services
            to deal with or facilitate customer service, carry out your instructions, deal with or respond to any enquiries given by (or purported to be given by) you or on your behalf
            to contact you or communicate with you via voice call, text message and/or fax message, email and/or postal mail or otherwise for the purposes of administering and/or managing your relationship with us or your use of our Services, such as but not limited to communicating administrative information to you relating to our Services. You acknowledge and agree that such communication by us could be by way of the mailing of correspondence, documents or notices to you, which could involve disclosure of certain personal data about you to bring about delivery of the same as well as on the external cover of envelopes/mail packages;
            to inform you when another user has sent you a private message or posted a comment for you on the Games or Site
            to conduct research, analysis and development activities (including, but not limited to, data analytics, surveys, product and service development and/or profiling), to analyst how you use our Services, to improve our Services or products and/or to enhance your customer experience;
            to allow for advertising and other audits and surveys to, among other things, validate the size and composition of our target audience, and understand their experience with ITSOL SYSTEM CO., LTD’s Services
            for marketing and, in this regard, to send you by various modes of communication marketing and promotional information and materials relating to products and/or services (including, without limitation, products and/or services of third parties whom ITSOL SYSTEM CO., LTD may collaborate or tie up with) that ITSOL SYSTEM CO., LTD (and/or its affiliates or related company) may be selling, marketing or promoting, whether such products or services exist now or are created in the future. You can unsubscribe from receiving marketing information at any time by using the unsubscribe function within the electronic marketing material. We may use your contact information to send newsletters from ITSOL SYSTEM CO., LTD and from ITSOL SYSTEM CO., LTD related companies
            to respond to legal processes or to comply with or as required by any applicable law, governmental or regulatory requirements of any relevant jurisdiction,
            to produce statistics and research for internal and statutory reporting and/or record-keeping requirements
            to carry out due diligence or other screening activities (including, without limitation, background checks) in accordance with legal or regulatory obligations or our risk management procedures that may be required by law or that may have been put in place by us
            to audit our Services or ITSOL SYSTEM CO., LTD’s business
            to prevent or investigate any actual or suspected violations of our Terms of Service, fraud, unlawful activity, omission or misconduct, whether relating to your use of our Services or any other matter arising from your relationship with us
            to store, host, back up (whether for disaster recovery or otherwise) of your personal data, whether within or outside of your jurisdiction;
            to deal with and/or facilitate a business asset transaction or a potential business asset transaction, where such transaction involves ITSOL SYSTEM CO., LTD as a participant or involves only a related company or affiliate of ITSOL SYSTEM CO., LTD as a participant or involves ITSOL SYSTEM CO., LTD and/or any one or more of ITSOL SYSTEM CO., LTD’s related company or affiliates as participant(s), and there may be other third-party organizations who are participants in such transaction. A “business asset transaction” refers to the purchase, sale, lease, merger, amalgamation or any other acquisition, disposal or financing of an organization or a portion of an organization or of any of the business or assets of an organization; and/or
            any other purposes which we notify you of at the time of obtaining your consent (collectively, the “purposes”)
            As the purposes for which we will/may collect, use, disclose or process your personal data depend on the circumstances at hand, such purpose may not appear above. However, we will notify you of such other purpose at the time of obtaining your consent, unless processing of the applicable data without your consent is permitted by the related regulation.</p>

          <h4>7. HOW DOES ITSOL SYSTEM CO., LTD PROTECT AND RETAIN CUSTOMER INFORMATION?</h4>
          <p>ITSOL SYSTEM CO., LTD implement a variety of security measures and strive to ensure the security of your personal data on ITSOL SYSTEM CO., LTD systems. User personal data is contained behind secured networks and is only accessible by a limited number of employees who have special access rights to such systems. However, there can inevitably be no guarantee of absolute security
            ITSOL SYSTEM CO., LTD will retain personal data in accordance with the Privacy Laws and/or other applicable laws. That is, ITSOL SYSTEM CO., LTD will destroy or anonymize your personal data when we have reasonably determined that (i) the purpose for which that personal data was collected is no longer being served by the retention of such personal data; (ii) retention is no longer necessary for any legal or business purposes; and (iii) no other legitimate interest warrant further retention of such personal data. If you cease using ITSOL SYSTEM CO., LTD Services, or your permission to use the Site, Games and/or the Services is terminated or withdrawn, ITSOL SYSTEM CO., LTD may continue storing, using and/or disclosing your personal data in accordance with this Privacy Policy and our obligations under the Privacy Laws. Subject to applicable law, we may securely dispose of your personal data without prior notice to you.</p>

          <h4>8. DOES ITSOL SYSTEM CO., LTD DISCLOSE THE INFORMATION IT COLLECTS FROM ITS VISITORS?</h4>
          <p>In conducting our business, we will/may need to disclose your personal data to our third-party service providers, agents and/or our affiliates or related company, and/or other third parties, for one or more of the above-stated Purposes. Such third-party service providers, agents and/or affiliates or related company and/or other third parties would be processing your personal data either on ITSOL SYSTEM CO., LTD behalf or otherwise, for one or more of the above-stated Purposes. Such third parties include, without limitation:
            our subsidiaries, affiliates and related company
            contractors, agents, service providers and other third parties we use to support our business. These include but are not limited to those which provide administrative or other services to us such as mailing houses, telecommunication companies, information technology companies and data centers
            a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of ITSOL SYSTEM CO., LTD’s assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal data held by ITSOL SYSTEM CO., LTD about our users is among the assets transferred; or to a counterparty in a business asset transaction that ITSOL SYSTEM CO., LTD or any of ITSOL SYSTEM CO., LTD’s affiliates or related company is involved in; and
            third parties to whom disclosure by us is for one or more of the Purposes and such third parties would in turn be collecting and processing your personal data for one or more of the Purposes
            This may require, among other things, disclosing your gaming history and/or disclosing records showing which games were viewed by which IP addresses. We will also share statistical and demographic information about our users and their use of the Services with suppliers of advertisements and programming.
            For the avoidance of doubt, in the event that Privacy Laws or other applicable laws permit an organization such as us to collect, use or disclose your personal data without your consent, such permission granted by the laws shall continue to apply.
            Third parties may unlawfully intercept or access personal data transmitted to or contained on the site, technologies may malfunction or not work as anticipated, or someone might access, abuse or misuse information through no fault of ours. We will nevertheless deploy reasonable security arrangements to protect your personal data as required by the Privacy Laws; however, there can inevitably be no guarantee of absolute security such as but not limited to when unauthorized disclosure arises from malicious and sophisticated hacking by malcontents through no fault of ours.</p>
          <h4>9. INFORMATION ON CHILDREN (EXCEPT WHO IS LIVING IN EU)</h4>
          <p>Our Services are diverse and cater to a wide range of age groups. For children under the age of 14, creating an account and using our Services requires the permission and supervision of the child's parent or guardian. Parents or guardians of children under the age of 14 please supervise and ensure that the children under the age of 14 in their custody do not register themselves for an account to use the Services and/or provide personal information to us without the supervision or consent of the child's parent or guardian. In the event that personal information of children under 14 years of age is provided to us by a parent or guardian, or a parent or guardian agrees to provide us with the child's information, the parent/guardian agrees to provide us with the child's information. The parent or guardian hereby consents to the processing and use of the information of children under the age of 14 concerned, and agrees to be governed by this Policy on behalf of the ward. We will lock down any accounts used solely by such children and will remove and/or delete any personal data that We believe has been submitted by any children under 14 the age.</p>

          <h4>10. INFORMATION COLLECTED BY THIRD PARTIES</h4>
          <p>Our Site and Games use Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies, which are text files placed on your device, to help the website analyst how users use our Services. The information generated by the cookie about your use of the Services or device (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of our Services, compiling reports on website activity for website operators and providing other services relating to website activity and Internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google.
            <br></br>
            We, and third parties, may from time to time make software applications downloads available for your use on or through the Services. These applications may separately access, and allow a third party to view, your identifiable information, such as your name, your User ID, your device’s IP Address or other information such as what game you are playing during any given session, and any cookies that you may previously have installed or that were installed for you by a third-party software application or website. Additionally, these applications may ask you to provide additional information directly to third parties. Third party products or services provided through these applications are not owned or controlled by ITSOL SYSTEM CO., LTD. You are encouraged to read the terms and other policies published by such third parties on their websites or otherwise.</p>

          <h4>11. DISCLAIMER REGARDING SECURITY AND THIRD-PARTY SITES</h4>
          <p>ITSOL SYSTEM CO., LTD DO NOT GUARANTEE THE SECURITY OF PERSONAL DATA AND/OR OTHER INFORMATION THAT YOU PROVIDE ON THIRD PARTY SITES. We do implement a variety of security measures to maintain the safety of your personal data that is in our possession or under our control. Your personal data is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the personal data confidential. When you place orders or access your personal data, we offer the use of a secure server. All personal data or sensitive information you supply is encrypted into our databases to be only accessed as stated above.
            In an attempt to provide you with add-on value, we may choose various third-party websites to link to, and frame within, the Site or Games. We may also participate in co-branding and other relationships to offer e-commerce and other services and features to our visitors. These linked sites have separate and independent privacy policies as well as security arrangements. Even if the third party is affiliated with us, we have no control over these linked sites, each of which has separate privacy and data collection practices independent of us. Data collected by our co-brand partners or third-party web sites (even if offered on or through our Site or Games) may not be received by us.
            We therefore have no responsibility or liability for the content, security arrangements (or lack thereof) and activities of these linked sites. These linked sites are only for your convenience and you therefore access them at your own risk. Nonetheless, we seek to protect the integrity of our Site and Games and the links placed upon each of them and therefore welcome any feedback about these linked sites (including, without limitation, if a specific link does not work).</p>

          <h4>12. HOW CAN YOU WITHDRAW CONSENT, REQUEST ACCESS TO OR CORRECT INFORMATION YOU HAVE PROVIDED TO US?</h4>
          <p>Withdrawing consent
            You may withdraw your consent for the collection, use and/or disclosure of your personal data in our possession or under our control by sending an email to our Personal Data Protection Officer at our email: contact@itsolsys.net, and we will process such requests in accordance with this Privacy Policy and our obligations under the Privacy Laws and other applicable law.
            However, your withdrawal of consent may mean that we will not be able to continue providing the Services to you, and we may need to terminate your existing relationship and/or the contract you have with us.
            Requesting access to, or correction of personal data
            If you have an account with us, you may personally access and/or correct your personal data currently in our possession or control through the Account Settings page or tab on the Site or Games. If you do not have an account with us, you may request to access and/or correct your personal data currently in our possession or control by submitting a written request to us. We will need enough information from you in order to ascertain your identity as well as the nature of your request so as to be able to deal with your request. Hence, please submit your written request by sending an email to our Personal Data Protection Officer at our email contact@itsolsys.net
            We may charge you a reasonable fee for the handling and processing of your requests to access your personal data. If we so choose to charge, we will provide you with a written estimate of the fee we will be charging. Please note that we are not required to respond to or deal with your access request unless you have agreed to pay the fee
            We reserve the right to refuse to correct your personal data in accordance with the provisions as set out in Privacy Laws, where they require and/or entitle an organization to refuse to correct personal data in stated circumstances.</p>

          <h4>13. ADDITIONAL PROVISIONS FOR EU USERS ONLY</h4>
          <p>The provisions in this Section only apply if you are a user who is located in the European Union (“EU”). These provisions take precedence over any inconsistent provisions in the remainder of this Privacy Policy.
            <br></br>
            Portability
            You have the right to obtain from us the Personal Data that you have previously provided to us, and which we process on the basis of your consent in a structured, commonly used and machine-readable format and a right to request that we transfer such Personal Data to another party.
            If you wish for us to transfer the Personal Data to another party, please ensure you detail that party and note that we can only do so where it is technically feasible. We are not responsible for the security of the Personal Data or its processing once received by the third party. We also may not provide you with certain Personal Data if providing it would interfere with another’s rights (e.g. where providing the Personal Data we hold about you would reveal information about another person).
            Erasure
            You may request that we erase the Personal Data we hold about you in the following circumstances:
            You believe that it is no longer necessary for us to hold the Personal Data we hold about you.
            We are processing the Personal Data we hold about you on the basis of your consent, and you wish to withdraw your consent and there is no other ground under which we can process the Personal Data;
            We are processing the Personal Data we hold about you on the basis of our legitimate interest and you object to such processing.  Please provide us with detail as to your reasoning so that we can assess whether there is an overriding interest for us to process and retain such Personal Data.
            You no longer wish us to use the Personal Data we hold about you in order to send you promotions, special offers, marketing and lucky draws; or
            You believe the Personal Data we hold about you is being unlawfully processed by us.
            Please provide as much detail as possible on your reasons for the request to assist us in determining whether you have a valid basis for erasure. However, we may retain the Personal Data if there are valid grounds under law for us to do so (e.g., for the defense of legal claims or freedom of expression) but we will let you know if that is the case.  Please note that after deleting the Personal Data, we may not be able to provide the same level of servicing to you as we will not be aware of your preferences.
            You should note that, regulations in some countries and territories (such as Vietnam) require that Service Providers such as ITSOL SYSTEM CO., LTD are required to keep information, Personal Data of the user. used for a certain period (maybe up to two (2) years) (“Storage Term”) from the last time the user uses the service; You agree that, in this case, the provisions of the above countries and territories shall prevail; therefore, a request to delete Your Personal Data will only be possible after the end of the aforementioned Retention Term.
            Retention of Personal Data
            We retain your Personal Data as long as needed to provide services or products to you, or as required or permitted by applicable laws, such as tax and accounting laws. If you make a request for erasure of your Personal Data 3 and we determine that there is a valid basis for your request, we will endeavor to erase the Personal Data within 30 days of your request, or inform you if it will take longer.
            <br></br>
            Objection against Profiling and Targeted Marketing
            At any time, you have the right to object to our processing of Personal Data about you in order to send you promotions, special offers and marketing messages, including where we build profiles for such purposes and we will stop processing the Personal Data for that purpose. Please provide as much detail as possible on your reasons for the request to assist us in determining whether you have a valid basis for objection. However, we may retain the Personal Data if there are valid grounds under law for us to do so.
            <br></br>
            Restriction of Processing to Storage Only
            You have a right to request that we restrict the processing of the Personal Data we hold about you to storage purposes only, where:
            <br></br>
            You believe the Personal Data is not accurate, for the period it takes for us to verify whether the Personal Data is accurate
            We wish to erase the Personal Data but you want us to just store it instead.
            We wish to erase the Personal Data as it is no longer necessary for our purposes but you require it to be stored for the establishment, exercise or defense of legal claims; or
            You have objected to us processing Personal Data we hold about you on the basis of our legitimate interest and you wish us to stop processing the Personal Data whilst we determine whether there is an overriding interest in us processing and retaining such Personal Data.
            Transfer of Personal Data outside the EU
            Your Personal Data may be transferred outside of the EU.  We take appropriate steps to ensure that recipients of your Personal Data are bound to duties of confidentiality and we implement measures such as appropriate contractual clauses to ensure that the recipients of such transfer protect and treat your Personal Data in accordance with all applicable personal data protection laws.
            <br></br>
            Information on Children
            The services are not intended for children under the age of 16 who are located in the EU. We do not knowingly collect or maintain any personal data or non-personally-identifiable information from anyone under the age of 16 who is located in the EU, nor is any part of our Site, Games or other Services directed to children under the age of 16 who are located in the EU. We will close any accounts used exclusively by such children and will remove and/or delete any personal data we believe was submitted by any child under the age of 16 who is located in the EU.</p>

          <h4>14. STORAGE LOCATION AND TRANSFERRING TO OTHER COUNTRIES</h4>
          <p>ITSOL SYSTEM CO., LTD stores Your Personal Data globally and shares it with companies in ITSOL SYSTEM CO., LTD Group to carry out the activities specified in this Policy. ITSOL SYSTEM CO., LTD may enter into a secondary contract to process, or share, your personal data with third parties located in countries outside of your country. As a result, Your Personal Data, may be subject to privacy laws that differ from those in Your country.
            For services that provided to the Vietnamese market, by ITSOL SYSTEM CO., LTD and/or its subsidiaries, affiliates, member companies with headquarters located in Vietnam, Your Personal Data shall be stored in Vietnam in accordance with the provisions of Vietnamese law.
            For EU users' Personal Data, it will be stored and transferred in accordance with Article 13 of this Policy.</p>

          <h4>15. QUESTIONS, CONCERNS OR COMPLAINTS? CONTACT ITSOL SYSTEM CO., LTD</h4>
          <p>If you have any questions, concerns or complaints about our privacy practices, please contact us at:
            - Email: contact@itsolsys.net
            - Address: Prea Kompurs, Dang Kao, Phnom Penh 12000 Cambodia.
            - Hotline: 086484998
            - Website:</p>

          <h4>16. THANK YOU FOR PLAY GAMES AND FOLLOW OUR PRIVACY POLICY.</h4>

        </Col>
      </Row>
    </div>
  )
};

export default Policy;
