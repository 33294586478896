import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, HashRouter as Router } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Event from "./pages/Event";
import Policy from "./pages/Policy";
import 'antd/dist/reset.css';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="event" element={<Event />} />
          <Route path="policy" element={<Policy />} />
        </Route>
      </Routes>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

