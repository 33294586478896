import { Row, Col, Image, Button } from 'antd'

const Event = () => {
  return (
    <div style={{backgroundColor: '#0C003D', color: 'white', fontSize: '16px'}}>
      <Row align="middle" style={{height: '90vh'}}>
        <Col align="middle" textAlign="center" span={24}>
          No Event..
        </Col>
      </Row>
    </div>
  )
};

export default Event;