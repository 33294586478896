import { Outlet } from "react-router-dom";
import MyHeader from './Header'
import Footer from './Footer'
import DocumentTitle from "react-document-title"
import React, { Component } from 'react'

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    const { title } = this.props;
    return (
      <DocumentTitle title={`${title ? title + ' | ' : ''} Dokluy Slot`}>
        <div>
          <MyHeader {...this.props} />
          <div>
            <div className="primary" style={{backgroundColor: '#000', margin: '0, 40px'}}>
              <Outlet />
            </div>
            <Footer {...this.props} />
          </div>
        </div>
      </DocumentTitle>
    )}
}

export default Layout;
