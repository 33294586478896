import React, { Component } from 'react'
// import { Link } from "react-router-dom";
import { Form, Input, Col, Row, Button } from 'antd';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const onFinish = (values) => {
            console.log('Success:', values);
        };

        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
        };

        return (
            <Row align="top" style={{ backgroundColor: '#B71F07', padding: '10px', color: 'white', fontSize: '16px' }}>
                <Col lg={8} sm={24} align="left" style={{ height: '100%', padding: '10px'}}>
                    <div className="logo">
                        <img className="img-fluid" style={{ maxWidth: "90%" }} src="images/logo.png" alt="" />
                    </div>
                </Col>

                <Col lg={8} sm={12} align="left">
                    <p>Dokluy Slots</p>
                    <p>ITSOL SYSTEM CO.,LTD</p>
                    <p>Address: Prea Kompurs, Dang Kao, Phnom Penh Cambodia.</p>
                </Col>
                <Col lg={8} sm={12} align="left">
                    <p>Mail: keosovann@itsolsys.net</p>
                    <p>Hotline: 086484998</p>
                </Col>
            </Row>
        )
    }
}

export default Footer;
